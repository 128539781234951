import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/Login'));
const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));
const Register = React.lazy(() => import('../pages/Register'));
const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword'));
const RecoverPassword = React.lazy(() => import('../pages/RecoverPassword'));

export default [
  { path: '/', Component: Home },
  { path: '/login', Component: Login },
  { path: '/loginAdmin', Component: LoginAdmin },
  { path: '/cadastro', Component: Register },
  { path: '/esquecisenha', Component: ForgetPassword },
  { path: '/alterarsenha/:token', Component: RecoverPassword },
  { path: '*', Component: Home },
];
