import React from 'react';
import { Routes, Route } from 'react-router-dom';

import adminRoutes from './app.admin.nav.routes';
import authRoutes from './auth.nav.routes';

const AppAdminRoutes = () => (
  <Routes>
    {adminRoutes.map(({ path, Component }, index) => (
      <Route key={index} path={path} element={<Component />} />
    ))}
    {authRoutes.map(({ path, Component }, index) => (
      <Route key={index} path={path} element={<Component />} />
    ))}
  </Routes>
);

export default AppAdminRoutes;
