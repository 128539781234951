import React from 'react';

const Dashboard = React.lazy(() => import('../pages/DashboardAdmin'));
const Admins = React.lazy(() => import('../pages/Admins'));
const Clientes = React.lazy(() => import('../pages/Clientes'));
const Receptores = React.lazy(() => import('../pages/Receptores'));
const Empresas = React.lazy(() => import('../pages/Empresas'));
const Categorias = React.lazy(() => import('../pages/Categorias'));
const Arquivos = React.lazy(() => import('../pages/Files'));
const ArquivosUpload = React.lazy(() => import('../pages/FilesUploads'));
const Sliders = React.lazy(() => import('../pages/Sliders'));

const routes = [
  {
    path: 'admin/',
    name: 'Dashboard',
    Component: Dashboard,
  },
  {
    path: '/admin/administradores',
    name: 'Administradores',
    Component: Admins,
  },
  {
    path: '/admin/clientes',
    name: 'Clientes',
    Component: Clientes,
  },
  {
    path: '/admin/receptores',
    name: 'Receptores',
    Component: Receptores,
  },
  {
    path: '/admin/empresas',
    name: 'Empresas',
    Component: Empresas,
  },
  {
    path: '/admin/categorias',
    name: 'Categorias',
    Component: Categorias,
  },
  {
    path: '/admin/arquivos',
    name: 'Arquivos',
    Component: Arquivos,
  },
  {
    path: '/admin/arquivos-cadastro',
    name: 'Arquivos',
    Component: ArquivosUpload,
  },
  {
    path: 'admin/sliders',
    name: 'Sliders',
    Component: Sliders,
  },
];

export default routes;
