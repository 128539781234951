import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import NotificationAlert from 'react-notification-alert';

function Notification(_props, ref) {
  const notification = useRef();

  useImperativeHandle(ref, () => ({
    notify: ({ place, title, message, color, icon, autoDismiss, closeButton }) => {
      const options = {
        place: place || 'tr',
        message: (
          <div className="d-flex flex-column">
            <span className="alert-title">{title || 'Mensagem'}</span>
            <span>{message}</span>
          </div>
        ),
        type: color || 'success',
        icon: icon || 'fas fa-bell',
        autoDismiss: autoDismiss || 5,
        closeButton,
      };
      notification.current.notificationAlert(options);
    },
  }));

  return <NotificationAlert ref={notification} />;
}

export default forwardRef(Notification);
