import React from 'react';
import { Routes, Route } from 'react-router-dom';

import authRoutes from './auth.nav.routes';
// Pages
export const AuthRoutes = () => (
  <Routes>
    {authRoutes.map(({ path, Component }, index) => (
      <Route key={index} path={path} element={<Component />} />
    ))}
  </Routes>
);

export default AuthRoutes;
