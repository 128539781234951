import React from 'react';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Arquivos = React.lazy(() => import('../pages/UserFiles'));
const Receptores = React.lazy(() => import('../pages/UserReceptorFiles'));
const ArquivosTechfive = React.lazy(() => import('../pages/UserTechfiveFiles'));
const Profile = React.lazy(() => import('../pages/Profile'));

const routes = [
  {
    path: 'admin-usuario',
    name: 'Dashboard',
    Component: Dashboard,
  },
  {
    path: 'admin-usuario/arquivos',
    name: 'Meus Arquivos',
    Component: Arquivos,
  },
  {
    path: 'admin-usuario/receptores',
    name: 'Receptores',
    Component: Receptores,
  },
  {
    path: 'admin-usuario/arquivos-techfive',
    name: 'Arquivos Techfive',
    Component: ArquivosTechfive,
  },
  {
    path: 'admin-usuario/perfil',
    name: 'Profile',
    Component: Profile,
  },
];

export default routes;
